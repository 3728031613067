import React from 'react';
import { Box, Button, Heading } from 'grommet';
import { Link } from 'react-router-dom';


export const Create = () => {
  return (
    <Box
      background="#201d26"
      justify='center'
      style={{ height: 'calc(100svh - 48px)'}}
      width={'100%'}
      animation={'zoomOut'}
    >
      <Box margin={{ left: '40px'}}>
        <Heading fill>evntlab</Heading>
        <Link to='/s'> 
        <Button
          label="🔎 Find (coming soon)"
          primary 
        />
      </Link>
        <Link to='/e'> 
        <Button
          css={{ marginTop: '8px'}}
          label="🪄 Create "
          primary 
        />
      </Link>
      </Box>
    </Box>
  )


}