import React from 'react';

import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { Create } from '../views/create/create';
import { Home } from '../views/home';
import { Box, Paragraph } from 'grommet';

const main = () => {

  return (
    <div>
      <Router>
      <Routes>
        <Route path="/" Component={Create}/>
        <Route path="/e" Component={Home} />
        <Route path="/s" Component={() => <Box animation={'fadeIn'} justify='center' height='100svh'  align='center'><Paragraph color="white">coming soon</Paragraph></Box>} />
      </Routes>
      </Router>
    </div>

  )

}

export default main