import React, { useContext } from 'react';
import { Box, Button, Heading, Paragraph, ResponsiveContext } from 'grommet';
import photo from '../assets/cover-photo.jpeg'


export const Wizard = () => {

  return (
    <Box align="center" background="#421f85" height={'50vh'} width='100%' justify="center" pad={"none"} style={{padding : 0}} animation={'fadeIn'}>


      <Box gap='small'>
      <Button
        label="Set the date and time"
        primary 
        onClick={() => alert('hello, world')}
      />
      <Button
        label="Find the date and time"
        primary 
        onClick={() => alert('hello, world')}
      />
      </Box>
    </Box>
  )
}

export const Event = () => {
  const size = useContext(ResponsiveContext);
  const [ showCoverArt, setShowCoverArt ]  = React.useState(true)
  console.log("size: ", size)

  const contianerHeight = 'calc(100svh - 48px)' 
  const infoHeight = size === 'small' ? 'calc(100svh - 48px)' : 'calc(40svh - 48px)' 
  const bottomHeight = size === 'small' ? 'calc(100svh - 48px)' : 'calc(60svh - 48px)' 


  if (size === 'small' && showCoverArt) {
    return (
    <Box height={contianerHeight} width="100svw" background={'#201d26'} animation={size === 'small' ? 'slideUp' : undefined } css={{ paddingLeft: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <img src={photo} css={{
            display: 'block',
            maxWidth: '100%',
            maxHeight: '100%',
            width: 'auto',
            height: 'auto'
          }} alt="test"></img>
          <Button onClick={() => setShowCoverArt(!showCoverArt)}>Flip</Button>


    </Box>

    )
  }


  return (
    <Box height={contianerHeight} width="100svw" background={'#201d26'} animation={size === 'small' ? 'slideUp' : undefined } css={{ paddingLeft: 0}}>
      <div css={{ padding: '16px', margin: size !== 'small' ? '12px': '', infoHeight }} >
        <Box direction={size !== 'small' ? 'row' : 'column'} width={"100%"} justify='between'>
          <Heading size='small'>Untilted</Heading>
          {size !== 'small' && (
            <Box>
              <Button size={size} label="Copy Link" secondary />
              <Button size={size} css={{  marginTop: "10px"}}  label="Send" primary />
            </Box>
          )}
        </Box>
        <div>
          <div css={{ display: 'flex', flexDirection: 'row'}}>
            <Paragraph css={{ margin: 10}}><strong>Time:</strong> </Paragraph>
          </div>
          <Paragraph css={{ margin: 10}}><strong>Location:</strong> </Paragraph>
          <div css={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center'}}>
            <Paragraph css={{ margin: 10}}><strong>Confirmed (0) : </strong></Paragraph>
            <div css={{marginLeft: '10px', display: 'flex', flexDirection: 'row', gap: '8px' }}>
            </div>
          </div>
          <Paragraph css={{ margin: 10}}><strong>Sent (0)</strong> </Paragraph>
        </div>
        <div>
          {size === 'small' && (<Box css={{marginTop: '36px'}}>
            <Button size={size} label="Copy Link" secondary />
            <Button size={size} css={{  marginTop: "10px"}}  label="Send" primary />
          </Box>)}
        </div>
      </div>
      {size !== 'small' && <div css={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: bottomHeight, padding: '16px', margin: '12px'}}>
        <div css={{ width: '50%', height: '90%', padding: '16px', margin: '12px', backgroundColor: '#34303933', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
          <Paragraph>Chat coming soon...</Paragraph>
        </div>
        <div css={{ width: '50%', height: '90%', padding: '16px', margin: '12px', display: 'flex', justifyContent: 'center', alignItems:'center', backgroundColor: '#00000021'}}>
          <img src={photo} css={{
            display: 'block',
            maxWidth: '90%',
            maxHeight: '90%',
            width: 'auto',
            height: 'auto'
          }} alt="test"></img>


        </div>
      </div>}

      {size === 'small' && (
        <div>
          <div css={{ width: '', height: '90%', padding: '16px', margin: '12px', backgroundColor: '#34303933', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
            <Paragraph>Chat coming soon...</Paragraph>
          </div>
          <Button onClick={() => setShowCoverArt(!showCoverArt)}>Flip</Button>

        </div>
      )}

    </Box>
  )
}


export const Home = () => {

  return (
    <>
      <Event />
    </>
  )
}
