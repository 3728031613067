import React from 'react';
import Routes from './routes';
import { Grommet } from 'grommet';

const App = () => {
  return (
      <Grommet full>
        <div css={{ padding: '24px', height: '100svh'}}>
          <Routes/>
        </div>
      </Grommet>
  );
}

export default App;
